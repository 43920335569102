import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export interface ValidResponseType {
  data: object;
  meta: { total_pages: number, total_counts: number };
  catalogues: {};
}

export interface InvalidResponseType {
  errors: string;
}

interface Role {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  page_names: string[];
}

interface AccountAttributes {
  activated: boolean;
  qr_generated: boolean;
  country_code: string;
  email: string;
  upi_id: string | null;
  store_type: string;
  full_name: string;
  user_name: string;
  business_popup: boolean;
  full_phone_number: string;
  phone_number: string;
  type: string | null;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  role: Role;
  is_disabled: boolean;
  my_bio: string | null;
  updated_mobile_number: string | null;
  updated_email: string | null;
  same_drop_off: boolean;
  shipment_type: string;
  business_name: string;
  business_type: string;
  profile_photo: string | null;
  background_photo: string | null;
  qr_code: string | null;
}

interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface AccountData {
  data: Account[];
}


interface Category {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  admin_user_id: number | null;
  rank: number | null;
  light_icon_active: { url: string | null };
  light_icon_inactive: { url: string | null };
  dark_icon: { url: string | null };
  dark_icon_active: { url: string | null };
  dark_icon_inactive: { url: string | null };
  identifier: string | null;
  slug: string;
}

interface SubCategory {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  rank: number | null;
}

interface AccountTagList {
  id: number;
  first_name: string | null;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  role_id: number;
  full_name: string;
  email_verified: boolean;
  phone_verified: boolean;
  business_name: string;
  business_type: string;
  admin_verified: boolean;
  updated_mobile_number: string;
  is_disabled: boolean;
  my_bio: string;
  updated_email: string;
  business_popup: boolean;
  password_digests: string[];
  shipment_type: string;
  same_drop_off: boolean;
  marked_destroy: boolean;
  upi_id: string;
  qr_generated: boolean;
  latitude: number;
  longitude: number;
  current_city: string;
  store_type: string;
}

interface ProductImage {
  id: number;
  url: string;
}

interface ManufacturingDetail {
  data: any;
}

interface Product {
  id: string;
  type: string;
  checked: boolean;
  attributes: {
    catalogue_id: number;
    price: string,
    category: Category;
    sub_category: SubCategory;
    brand: string | null;
    title: string;
    description: string;
    status: string;
    mrp: number;
    selling_price: number;
    ask_price: number;
    bargain: boolean;
    on_order: boolean;
    is_ask_price: boolean;
    is_brand: boolean;
    tags: any[];
    reviews: any[];
    sku: string;
    account: AccountTagList;
    images: any | null;
    host_name: string;
    host_bio: string;
    host_image: string;
    product_images: ProductImage[];
    inventory_details: any | null;
    shipment_charge: any | null;
    packaging_detail: any | null;
    manufacturing_detail: ManufacturingDetail;
    product_detail: any | null;
    product_videos: any | null;
    average_rating: number;
    catalogue_variants: any[];
    catalogues_specifications: any[];
  };
}

interface InventoryData {
  data: Product[];
}

interface InventoryCateData {
  catalogues: InventoryData;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoader: boolean;
  isSideBarOpen: boolean;
  previewImages: string[],
  filesToUpload: File[],
  activeMessage: boolean;
  taggedPeople: { name: string, x: number, y: number }[],
  isPhotoUploaded: boolean;
  isCaption: boolean;
  captionText: string;
  currentPage: number;
  inventoryList: Product[];
  inventoryListBackup: Product[];
  tagList: Account[];
  tagCurrentTotalPage: number;
  invCurrentTotalPage: number;
  invCurrentTotalCount: number;
  isInventoryOpen: boolean;
  checkedInventoryList: string[];
  checkedVarientList: string[];
  searchProductText: string;
  inventoryVarient: any[];
  inveVaritentCurId: string;
  inveCurSelectedName: string;
  inveCurSelectedDes: string;
  isVarientOpen: boolean;
  selectedProducts: Product[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationCommonController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postPostCreationAPICallId: string = "";
  getInventoryAPICallId: string = "";
  getTagListAPICallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      isLoader: false,
      isSideBarOpen: false,
      previewImages: [],
      filesToUpload: [],
      activeMessage: false,
      taggedPeople: [
        { name: "John Doe", x: 50, y: 20 },
        { name: "Jane Smith", x: 100, y: 80 },
      ],
      isPhotoUploaded: false,
      isCaption: false,
      captionText: "",
      currentPage: 1,
      inventoryList: [],
      inventoryListBackup: [],
      tagList: [],
      tagCurrentTotalPage: 0,
      invCurrentTotalPage: 0,
      invCurrentTotalCount: 0,
      isInventoryOpen: false,
      checkedInventoryList: [],
      checkedVarientList: [],
      searchProductText: "",
      inventoryVarient: [],
      inveVaritentCurId: "",
      inveCurSelectedName: "",
      inveCurSelectedDes: "",
      isVarientOpen: false,
      selectedProducts: [],
    };
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.handleInventory();
    this.handleTagList();
  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && (responseJson.data || responseJson.catalogues);
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType & AccountData & InventoryCateData) => {
    if (apiRequestCallId === this.postPostCreationAPICallId) {
      this.setState({ invCurrentTotalPage: 0 });
    }
    if (apiRequestCallId === this.getInventoryAPICallId) {
      const { data } = responseJson.catalogues;
      const combinedData = [...this.state.inventoryListBackup, ...data];
      const updatedInventoryList = Array.from(new Map(combinedData.map(item => [item.id, item])).values());
      this.setState({
        inventoryList: data,
        inventoryListBackup: updatedInventoryList,
        invCurrentTotalPage: responseJson.meta.total_pages,
        invCurrentTotalCount: responseJson.meta.total_counts,
        isLoader: false
      });
    }
    if (apiRequestCallId === this.getTagListAPICallId) {
      this.setState({ tagList: responseJson.data, tagCurrentTotalPage: responseJson.meta.total_pages });
    }
  };

  apiCallPostCreation = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint, body, type } = valueData;
    const token = (await getStorageData("singupLogin")) || "";
    const header = {
      "Content-Type": contentType,
      token,
    };
    let apiBody = body;
    if (type === "") {
      apiBody = JSON.stringify(body);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handlePostSideBar = () => {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
  }

  handlePostImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (this.state.previewImages.length === 6) { return; }
    if (files && files.length > 0) {
      const fileArray = Array.from(files);
      const imageUrls = fileArray.map((file) => URL.createObjectURL(file));

      this.setState((prevState) => ({
        previewImages: [...prevState.previewImages, ...imageUrls],
        filesToUpload: [...prevState.filesToUpload, ...fileArray],
      }));
    }
  };

  handlePostCaptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const captionText = event.target.value;
    this.setState({
      captionText: captionText
    });
    if (captionText === "") {
      this.setState({
        isCaption: true
      });
    } else {
      this.setState({
        isCaption: false
      });
    }
  };

  handlePostBackButton = () => {
    if (this.state.isInventoryOpen) {
      this.setState({
        isInventoryOpen: false
      })
    } else {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LiveStreaming"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message)
    }
  }

  handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      activeMessage: event.target.checked
    })
  };

  handleRemoveImage = (index: number) => {
    this.setState((prevState) => ({
      previewImages: prevState.previewImages.filter((_, i) => i !== index),
      filesToUpload: prevState.filesToUpload.filter((_, i) => i !== index),
    }));
  };

  handleRemoveAllImages = () => {
    this.setState({
      previewImages: [],
      filesToUpload: [],
    });
  };

  handleRemoveAllChecked = () => {
    const { inventoryList } = this.state;

    const updatedInventoryList = inventoryList.map(item => ({
      ...item,
      checked: false
    }));

    this.setState({
      checkedInventoryList: [],
      checkedVarientList: [],
      inventoryList: updatedInventoryList,
      selectedProducts: [],
    });
  };


  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ isLoader: true });
    this.setState({ currentPage: page }, () => {
      this.handleInventory();
    });
  };

  handleInventoryChecked = (event: React.ChangeEvent<HTMLInputElement>, inventory: Product) => {
    const { checkedInventoryList, inventoryList, inventoryVarient, checkedVarientList } = this.state;
    const isChecked = event.target.checked;
    const inventoryId = inventory.id;
    const inventoryTitle = inventory.attributes.title;
    const inventoryDesc = inventory.attributes.description;

    const updatedCheckedInventoryList = isChecked
      ? [...checkedInventoryList, inventoryId]
      : checkedInventoryList.filter(id => id !== inventoryId);

    const updatedInventoryList = inventoryList.map(item =>
      item.id === inventoryId ? { ...item, checked: isChecked } : item
    );

    const updatedCheckedVarientList = isChecked
      ? checkedVarientList
      : checkedVarientList.filter(
        variant => !inventoryVarient.some(inventoryVariant => inventoryVariant.id === variant)
      );

    this.setState({
      checkedInventoryList: updatedCheckedInventoryList,
      inventoryList: updatedInventoryList,
      inventoryVarient: inventory.attributes.catalogue_variants,
      inveVaritentCurId: inventoryId,
      inveCurSelectedName: inventoryTitle,
      inveCurSelectedDes: inventoryDesc,
      isVarientOpen: isChecked,
      checkedVarientList: updatedCheckedVarientList,
    });
  };

  handleInventoryVarientChecked = (event: React.ChangeEvent<HTMLInputElement>, inventory: Product) => {
    const { checkedVarientList, inventoryVarient } = this.state;
    const isChecked = event.target.checked;
    const inventoryVarientId = inventory.id;

    const updatedCheckedInventoryList = isChecked
      ? [...checkedVarientList, inventoryVarientId]
      : checkedVarientList.filter(id => id !== inventoryVarientId);

    const updatedInventoryVarientList = inventoryVarient.map(item =>
      item.id === inventoryVarientId ? { ...item, checked: isChecked } : item
    );

    this.setState({
      checkedVarientList: updatedCheckedInventoryList,
      inventoryVarient: updatedInventoryVarientList
    });
  }

  handleShownventory = () => {
    this.setState({ isInventoryOpen: true });
  }

  handleVarientAddBtn = () => {
    this.setState({ isVarientOpen: false });
  }

  handleInventoryAddBtn = () => {
    const { inventoryListBackup, checkedVarientList } = this.state;
    const matchedVariants = inventoryListBackup.flatMap(item =>
      item.attributes.catalogue_variants.filter(variant =>
        checkedVarientList.includes(variant.id)
      )
    );
    this.setState({
      selectedProducts: matchedVariants,
      isInventoryOpen: false
    });
  };

  findInventoryDetails = (whichType: string, catalogid: number) => {
    const matchedItem = this.state.inventoryListBackup.find(
      (item) => Number(item.id) === catalogid
    );
    if (matchedItem) {
      if (whichType === "name") {
        return matchedItem.attributes.title
      } else {
        return matchedItem.attributes.description
      }
    }
  }
  handleVarientModalClose = () => {
    const { checkedInventoryList, inventoryList } = this.state;
    const updatedCheckedInventoryList = checkedInventoryList.filter(id => id !== this.state.inveVaritentCurId)
    const updatedInventoryList = inventoryList.map(item =>
      item.id === this.state.inveVaritentCurId ? { ...item, checked: false } : item
    );
    this.setState({
      isVarientOpen: false,
      checkedInventoryList: updatedCheckedInventoryList,
      inventoryList: updatedInventoryList,
    });
  }

  handlePostShare = async () => {
    const errors = {
      isCaption: !this.state.captionText,
      isPhotoUploaded: this.state.filesToUpload.length === 0,
    };
    this.setState(errors);
    const hasErrors = Object.values(errors).some(error => error);
    if (!hasErrors) {
      const formData = new FormData();
      formData.append("data[attributes][active_message]", this.state.activeMessage.toString())
      formData.append("data[attributes][body]", this.state.captionText.trim())
      if (this.state.filesToUpload)
        this.state.filesToUpload.forEach((file: File) => {
          formData.append("data[attributes][images][]", file);
        });

      this.postPostCreationAPICallId = await this.apiCallPostCreation({
        method: configJSON.PostAPiMethod,
        endPoint: configJSON.postPostCreationEndPoint,
        body: formData,
        type: "formData"
      });
    }
  }

  handleInventory = async () => {
    this.getInventoryAPICallId = await this.apiCallPostCreation({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getInventoryEndPoint + `?page=${this.state.currentPage}` + `&search=${this.state.searchProductText}`,
      contentType: configJSON.validationApiContentType,
    });
  }

  handleTagList = async () => {
    this.getTagListAPICallId = await this.apiCallPostCreation({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getTagUserEndPoint + `&page=${this.state.currentPage}`,
      contentType: configJSON.validationApiContentType,
    });
  }

  handleInventoryPostSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentPage: 1,
      searchProductText: event.target.value
    }, () => {
      this.handleInventory();
    })
  }

  handleAddProductPost = async () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AddNewProduct"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }
  // Customizable Area End
}
