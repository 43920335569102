import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef } from "react";
const navigation = require('react-navigation');
import SendbirdChat, { FileCompat, SendbirdChatWith } from '@sendbird/chat';
import { GroupChannel, GroupChannelFilter, GroupChannelHandler, GroupChannelListOrder, GroupChannelModule } from "@sendbird/chat/groupChannel";
import { FileMessageCreateParams, UserMessage, UserMessageCreateParams } from "@sendbird/chat/message";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
export const SENDBIRD_INFO = { appId: '03C717AD-A9AE-41BF-AD88-05A83357E859' };
import AttachmentIcon from '@material-ui/icons/Attachment';
import { Box } from "@material-ui/core";
export interface UserUpdateParams {
  nickname?: string;
  userId?: string;
}

export interface ChatMessage {
    id: number;
    userId: number;
    text: string;
    time: string;
};
interface EmojiType {
  id: string;
  unified: string;
  name: string;
  keywords: string[];
  native: string;
  shortcodes: string[];
  custom: boolean;
};
export interface Receiver {
  nickname: string;
  userId: number;
  unseenMessageCount: number;
  lastMessage: string;
};

interface SendBirdCredential {
  id: number;
  user_id: string;
  nickname: string;
  token: string;
  account_id: number;
  created_at: string;
  updated_at: string;
  access_token: string | null;
  token_expires_at: string | null;
  profile_url: string | null;
}

export interface Account {
  id: number;
  name: string;
  bio: string | null;
  user_name: string;
  profile_photo: string | null;
  sendbird_credential: SendBirdCredential;
}

export interface BuyerDataResponse {
  accounts: Account[];
}

interface CreateChannelApiResponse {
  channel_url: string;
}
export interface Buyer {
  channelUrl: string;
  buyerName: string;
  buyerId: string;
  profileUrl: string;
  lastMessage?: string; 
  lastMessageTime?: number;
  messageType?: string;
  unreadCount?: number;
}

export interface ErrorPayloadType {
  key: string
}

export interface ValidResponseType {
  data: object
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface BuyerResponseType {
  channels: BuyerPayload[];
}

export interface MemberPayload {
  nickname: string;
  profile_url: string;
  user_id: string;
}

export interface BuyerPayload {
  channel_url: string;
  members: MemberPayload[];
}

export interface ExtendedUserMessages extends UserMessage {
  isDelivered?: boolean;
  isRead?: boolean;
  plainUrl?: string;
  name?:string;
}

interface ChannelMap {
  lastMessage?: string; 
  lastMessageTime?: number; 
  messageType?: string; 
  unreadCount?: number 
}

interface ApiPayloadType{
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
  type?: string;
}

interface RejectOnOrder{
  messageId: number, 
  productId: string, 
  message: string, 
  isAccept: boolean;
  expectedDate? : string;
}

interface OnOrderStatusApiResponse{
  request_status: string;
  id?: number;
  
}
interface RequestPayload {
  id: number;
  request_status: string;
  cancellation_reason?: string;
}
interface OnOrderStatusApiResponse{
  requests: RequestPayload;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    isOpen: boolean;
    isChatListOpen: boolean;
    isContactList: boolean;
    inputMessage: string;
    isEmoji: boolean;
    messages: ExtendedUserMessages[];
    newMessage: string;
    currrentEmojiPick: null | EmojiType;
    currentUserID: string;
    hasMore: boolean;
    currentChatUser: number;
    isLoading: boolean;
    messageInput: string;
    channel: GroupChannel | null;
    buyerContactList : Account[];
    channelCurrentUrl: string;
    currentBuyerId: string | number;
    sbChannelData: GroupChannel[];
    isFileModal: boolean;
    imageCurrentFile: File | null;
    imageCurrentName: string;
    isFileUploaded: boolean;
    fileCurrUrl: string;
    isSellerAppLoading: boolean;
    isAdminVerified: boolean;
    chatDateLabel: string;
    isLoadingPrevMsg: boolean;
    currUserChatIndex: number;
    isImageModalOpen: boolean;
    imageOpenUrl: string;
    isReceiverOnline: boolean;
    buyerList: Buyer[];
    selectedBuyer: Buyer | null;
    isListUpdating: boolean;
    isFromCreateApi: boolean;
    onOrderStatus: RejectOnOrder | null;
    isDeclineModal: boolean;
    declineReason: string;
    requestStatus: string,
    declineOtherReason: string
    declineOtherErr: boolean,
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerChatController extends BlockComponent<Props,S,SS> {
    // Customizable Area Start
    sendBird: SendbirdChatWith<GroupChannelModule[]> | null;
    chatContainerRef: React.RefObject<HTMLDivElement> = createRef();
    fileRef: React.RefObject<HTMLInputElement> = createRef();
    private debounceTimeout: NodeJS.Timeout | null = null;
    emojiPickerRef: React.RefObject<HTMLDivElement> = React.createRef();
    msgStatusTimer: ReturnType<typeof setTimeout> | number = 0;
    onlineCheckInterval: ReturnType<typeof setInterval> | number = 0; 
    getAllBuyerContactList: string = "";
    getAllChannelData: string = "";
    postCreateChannel: string = "";
    postOnOrderAcceptApiId: string = "";
    postOnOrderRejectApiId: string = "";     
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            isOpen: true,
            isChatListOpen: false,
            isContactList: false,
            inputMessage: "",
            isEmoji: false,
            messages:[],
            newMessage: "",
            currrentEmojiPick: null,
            currentUserID: "",
            hasMore: false,
            currentChatUser: 1,
            isLoading: false,
            messageInput: "",
            channel: null,
            buyerContactList:[],
            channelCurrentUrl: "",
            currentBuyerId: "",
            sbChannelData: [],
            isFileModal: false,
            imageCurrentFile: null,
            imageCurrentName: "",
            isFileUploaded: false,
            fileCurrUrl: "",
            isSellerAppLoading: false,
            isAdminVerified: false,
            chatDateLabel: 'Today',
            isLoadingPrevMsg: false,
            currUserChatIndex: 0,
            isImageModalOpen: false,
            imageOpenUrl: "",
            isReceiverOnline: false,
            buyerList: [],
            selectedBuyer: null,
            isListUpdating: false,
            isFromCreateApi: false,
            onOrderStatus: null,
            isDeclineModal: false,
            declineReason: "Overly Complex Requests",
            requestStatus: "",
            declineOtherReason: "",
            declineOtherErr: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.sendBird = null;
        this.msgStatusTimer= 0;
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
          );
          let apiResponseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          )
          if (apiResponseJson.status === 500) {
              this.showAlert("Error", "Internal Server Error!!");
              this.setState({ isSellerAppLoading: false });
              return;
          }
          if (apiResponseJson && !apiResponseJson.errors) {
            switch (apiRequestId) {
              case this.getAllBuyerContactList:
                this.getBuyerListSuccessCallBack(apiResponseJson);
                break;
              case this.getAllChannelData:
                this.getAllChannelSuccessCallBack(apiResponseJson);
                break;
              case this.postCreateChannel:
                this.postCreateChannelSuccessCallBack(apiResponseJson);
                break;
              case this.postOnOrderAcceptApiId:
                this.acceptedOnOrderCallBack(apiResponseJson);
                break;
              case this.postOnOrderRejectApiId:
                this.rejectOnOrderCallBack(apiResponseJson);
                break;
              default:
                break;
            }
          }
      }
        // Customizable Area End
    }


    // Customizable Area Start
  async componentDidMount() {
    let adminVerified = await getStorageData("adminVerified");
    this.setState({isAdminVerified : adminVerified});
    if(this.state.isAdminVerified){
    await this.getBuyerContactData();
    document.addEventListener("mousedown", this.handleEmojiClickOutside);
    const sbUserId = await getStorageData('sbUserId');
    const sbToken = await getStorageData("sbToken");
    this.initializeSendBirdSDK(sbUserId, sbToken);
    this.messageScrollToBottom();
    }
  };

  async componentWillUnmount() {
    clearInterval(Number(this.onlineCheckInterval));
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current.removeEventListener('scroll', this.handleMessageScroll);
    }
  };

  prepareBuyerList = async (response: BuyerResponseType) => {
    let buyerSet: Buyer[] = [];
    response.channels.forEach((channelPayload) => {
      buyerSet.push({
        channelUrl: channelPayload.channel_url,
        buyerName: channelPayload.members[0].nickname,
        buyerId: channelPayload.members[0].user_id,
        profileUrl: channelPayload.members[0].profile_url,
      });
    });
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;
    const collection = this.sendBird?.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });
    const channels = await collection?.loadMore();
    if (!channels) {
      return;
    }
    const channelDetailsMap = new Map<string, { lastMessage?: string; lastMessageTime?: number; messageType?: string; unreadCount?: number }>();
    for (const channel of channels) {
      const channelDataDetails = await this.getChannelDataDetails(channel);
      channelDetailsMap.set(channel.url, channelDataDetails);
    };
    this.updatedBuyerSetHandle(buyerSet, channelDetailsMap);
  };

  getChannelDataDetails = async (channel: GroupChannel) => {
    let lastMessage = '';
    let lastMessageTime = 0;
    let messageType = '';
    const messages = await channel.getMessagesByTimestamp(Date.now(), {
      isInclusive: false,
      prevResultSize: 50,
      nextResultSize: 0,
    });

    let unreadCount = 0;
    for (const message of messages) {
      const userMessage = message as UserMessage;
      if (
        userMessage.customType === 'read_receipt' ||
        userMessage.customType === 'read_all_receipt' ||
        userMessage.customType === 'online_receipt'
      ) {
        continue;
      }

      if (userMessage.createdAt > lastMessageTime) {
        lastMessage = this.checkIsLastOnOrder(userMessage);
        lastMessageTime = userMessage.createdAt;
        messageType = userMessage.customType || 'custom';
      }

      if (userMessage.data) {
        const messageData = JSON.parse(userMessage.data);
        if (messageData.read === false && userMessage.sender.userId !== this.state.currentUserID) {
          unreadCount++;
        }
      }
    }
    return { lastMessage, lastMessageTime, messageType, unreadCount };
  };

  checkIsLastOnOrder = (userMessage: UserMessage) => {
    if (userMessage.customType === "on_order") {
      return "On Order Request";
    } else {
      return userMessage.message;
    }
  };

  updatedBuyerSetHandle = (buyerSet: Buyer[], channelDetailsMap: Map<string, ChannelMap>) => {
    const updatedBuyerSet = buyerSet.map((buyer) => {
      const channelDetails = channelDetailsMap.get(buyer.channelUrl) || {};
      return {
        ...buyer,
        lastMessage: channelDetails.lastMessage,
        lastMessageTime: channelDetails.lastMessageTime,
        messageType: channelDetails.messageType,
        unreadCount: channelDetails.unreadCount,
      };
    });
    updatedBuyerSet.sort((a, b) => (b.lastMessageTime || 0) - (a.lastMessageTime || 0))
    this.setState({ buyerList: updatedBuyerSet }, async () => {
      if (!this.state.isListUpdating && !this.state.isFromCreateApi) {
        this.setState({ selectedBuyer: updatedBuyerSet[0], isSellerAppLoading: false, channelCurrentUrl: updatedBuyerSet[0].channelUrl });
        await this.loadSellerChannel(this.state.buyerList[0].channelUrl);
      } else if(this.state.isListUpdating){
        let channelIndex = this.state.buyerList.findIndex((buyerData) => buyerData.channelUrl === this.state.channelCurrentUrl);
        this.setState({ selectedBuyer: updatedBuyerSet[channelIndex], isListUpdating: false, isSellerAppLoading: false,});
      } else if(this.state.isFromCreateApi){
        let channelIndex = this.state.buyerList.findIndex((buyerData) => buyerData.channelUrl === this.state.channelCurrentUrl);
        this.setState({ selectedBuyer: updatedBuyerSet[channelIndex], isFromCreateApi: false, isSellerAppLoading: false,},async ()=>{
          await this.loadSellerChannel(this.state.channelCurrentUrl || "");
        });
      }
      setTimeout(() => {
        if (this.chatContainerRef.current) {
          this.chatContainerRef.current.addEventListener('scroll', this.handleMessageScroll);
        }
      }, 1000)
    });
  };

  handleMessageScroll = () => {
    if (this.chatContainerRef.current) {
      if (this.chatContainerRef.current.scrollTop === 0 && this.state.hasMore && !this.state.isLoading) {
        this.loadChatMessages(true);
      }
      this.handleDateChangeLabel();
    }
  };

  handleDateChangeLabel = () => {
    if (!this.chatContainerRef.current) return;
    const { messages } = this.state;
    const topScroll = this.chatContainerRef.current.scrollTop;
    const msgElments = this.chatContainerRef.current.children;
    for (let i = 0; i < msgElments.length; i++) {
      const element = msgElments[i] as HTMLElement;
      if (element.offsetTop > topScroll) {
        this.setState({ chatDateLabel: this.formatSelectedDate(messages[i].createdAt) });
        break;
      }
    }
  };
  
  getBuyerContactData = async () => {
    this.getAllBuyerContactList = await this.getBuyerContactApiCall();
  };

  getBuyerContactApiCall = async () => {
    let token = await getStorageData("singupLogin");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessageContactList = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageContactList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageContactList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessageContactList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSellerContactEndpoint
    );
    runEngine.sendMessage(requestMessageContactList.id, requestMessageContactList);
    return requestMessageContactList.messageId;
  };

  getAllChannelSuccessCallBack = async (responseJson: BuyerResponseType) => {
      await this.prepareBuyerList(responseJson);
      this.setState({ isSellerAppLoading: false });
  };

  getSellerChannelsApiCall = async (isFromListener = false) =>{
    this.setState({isSellerAppLoading: !isFromListener});
    this.getAllChannelData = await this.getAllChannelsApiCall();
  };

  getBuyerListSuccessCallBack = (responseJson: BuyerDataResponse) => {
    this.setState({ buyerContactList: responseJson.accounts});
  };

  loadSellerChannel = async (channelUrl: string) => {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;
    const collection = this.sendBird?.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });
    const channels = await collection?.loadMore();
    if (channels && channels.length > 0) {
      let channelIndex = channels.findIndex((channel) => channel.url === channelUrl);
      this.setState({ channel: channels[channelIndex] }, async () => {
        await this.setupSellerChannelHandler();
        this.sendOnlineStatus();
        this.onlineCheckInterval = setInterval(this.sendOnlineStatus, 20000); 
        await this.loadChatMessages();
      });
    }
  };

  getAllChannelsApiCall = async () => {
    let token = await getStorageData("singupLogin");
    const sbUserId = await getStorageData('sbUserId');
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: token
    };
    const requestMessageContact = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageContact.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessageContact.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
    );
    requestMessageContact.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getChannelData}?user_id=${sbUserId}`
    );
    runEngine.sendMessage(requestMessageContact.id, requestMessageContact);
    return requestMessageContact.messageId;
  };

  initializeSendBirdSDK = async (id: string, sbToken: string) => {
    this.setState({ currentUserID: id });
    const sendbirdChat = SendbirdChat.init({
      appId: SENDBIRD_INFO.appId,
      localCacheEnabled: true,
      modules: [new GroupChannelModule()]
    });
    try {
      await sendbirdChat.connect(id,sbToken);
    } catch (error) {
    }
    await sendbirdChat.setChannelInvitationPreference(true);
    this.sendBird = sendbirdChat;
    await this.getSellerChannelsApiCall();
  };

  setupSellerChannelHandler = async () => {
    if (this.sendBird) {
      const channelHandler = new GroupChannelHandler({
        onMessageReceived: (channel, message) => {
          this.refreshBuyerList();
          if (channel.url === this.state.channel?.url) {
            if (message.customType === 'read_receipt'){
              const data = JSON.parse(message.data);
              this.updateReadMessagesStatus(data.readMessageId, true);
            } else if (message.customType === 'read_all_receipt') {
              this.updateAllMessagesReadStatus(true);
            } else if (message.customType === 'online_receipt') {
              this.setState({ isReceiverOnline: true });
              this.clearOnlineStatus();
            } else {
              this.markAsRead(message as ExtendedUserMessages);
              this.setState((prevState) => ({
                messages: [...prevState.messages, message as ExtendedUserMessages]
              }), this.messageScrollToBottom);
            }
          }
        }
      });
      this.sendBird.groupChannel.addGroupChannelHandler('CHANNEL_HANDLER_ID', channelHandler);
    }
  };

  refreshBuyerList = () => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      this.setState({isListUpdating: true})
      this.getSellerChannelsApiCall(true);
    }, 500);
  }

  clearOnlineStatus = () => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      this.setState({ isReceiverOnline: false });
    }, 21000);
  };

  sendOnlineStatus = async () => {
    const { channel } = this.state;
    if (!channel) return;
    const params: UserMessageCreateParams = {
      message: `I am online.`,
      customType: 'online_receipt',
    };
    try {
      channel?.sendUserMessage(params).onSucceeded(async (sentMessage) => {
        await channel.deleteMessage(sentMessage);
      });
    } catch (error) {
    }
  }

  markAllMessageAsRead = async () => {
    const { channel } = this.state;
    if (!channel) return;
    const params: UserMessageCreateParams = {
      message: `Read all messages`,
      customType: 'read_all_receipt',
    };
    try {
      channel.sendUserMessage(params);
    } catch (error) {
    }
  };

  markAsRead = async (message: ExtendedUserMessages) => {
    const { channel } = this.state;
    if (!channel || !message) return;
    const params: UserMessageCreateParams = {
      message: `Read receipt for message ID: ${message.messageId}`,
      customType: 'read_receipt',
      data: JSON.stringify({ readMessageId: message.messageId }),
    };
    try {
      channel.sendUserMessage(params);
    } catch (error) {
    }
  };


  updateReadMessagesStatus = async (messageId: number, isRead = false) => {
      const { channel } = this.state;
      if (!channel) return;
      this.state.messages.forEach(async (message) => {
        if (message.messageId === messageId) {
          const paramsData = {
            customType: message.customType,
            data: JSON.stringify({ delivered: true, read: true }),
          };
          try {
            if(message.customType === 'audio' || message.customType === 'image') {
              let payload = JSON.parse(message.data);
              if(message.customType === 'image') {
                paramsData['data'] = JSON.stringify({ delivered: true, read: true, message: payload.message });
              }
              await channel.updateFileMessage(message.messageId, paramsData);
            } else  {
              await channel.updateUserMessage(messageId, paramsData);
            }
          } catch (error) {
          }
        }
      });
      this.sellerUpdateMessages(messageId,isRead);
  };

  sellerUpdateMessages = (messageId: number, isRead: boolean) => {
    const updatedMsgs = this.state.messages.map((message) => {
      if (message.messageId === messageId) {
        const param = {
          customType: message.customType,
          data: JSON.stringify({ delivered: true, read: true }),
        };
        if (message.customType === 'image') {
          let payload = JSON.parse(message.data);
          param['data'] = JSON.stringify({ delivered: true, read: true, message: payload.message });
        }
        const newMessage = {
          ...message,
          isRead: isRead || message.isRead,
          data: param.data,
        };
        return newMessage;
      }
      return message;
    });
    this.setState({ messages: updatedMsgs as ExtendedUserMessages[] });
  };

  delayFn = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  updateAllMessagesReadStatus = async (isReaded = false) => {
    const { channel } = this.state;
    if (!channel) return;
    try {
      for (const msgsdata of this.state.messages) {
        const params = {
          customType: msgsdata.customType,
          data: JSON.stringify({ delivered: true, read: true }),
        };
        if (msgsdata.sender.userId !== this.state.currentUserID) {
          if (msgsdata.customType === 'audio' || msgsdata.customType === 'image' || msgsdata.customType === 'pdf' || msgsdata.customType === 'doc') {
            let payload = JSON.parse(msgsdata.data);
            if (msgsdata.customType === 'image') {
              params['data'] = JSON.stringify({ delivered: true, read: true, message: payload.message });
            } else {
              params['data'] = JSON.stringify({ delivered: true, read: true, message: "", waveData: payload.waveData, duration: payload.duration });
            }
            await channel.updateFileMessage(msgsdata.messageId, params);
          } else {
            await channel.updateUserMessage(msgsdata.messageId, params);
          }
          await this.delayFn(300);
        }
      }
    } catch (error) {
    }
    this.updatedMessagesParameters(isReaded);
  };

  updatedMessagesParameters = (isReaded: boolean) => {
    const updatedMsgParams = this.state.messages.map((message) => {
      const paramData = {
        customType: message.customType,
        data: JSON.stringify({ delivered: true, read: true }),
      };
      if (message.customType === 'audio' || message.customType === 'image' || message.customType === 'pdf' || message.customType === 'doc') {
        let payload = JSON.parse(message.data);
        if (message.customType === 'image') {
          paramData['data'] = JSON.stringify({ read: true, message: payload.message, delivered: true });
        } else {
          paramData['data'] = JSON.stringify({ read: true, message: "", waveData: payload.waveData, duration: payload.duration, delivered: true });
        }
      }
      const newMessage = {
        ...message,
        isRead: message.isRead,
        data: isReaded ? paramData.data : message.data,
      };
      return newMessage;
    });
    this.setState({ messages: updatedMsgParams as ExtendedUserMessages[] });
  };


  loadChatMessages = async (isPagination = false) => {
    if (!this.state.channel) return;
    this.setState({ isLoading: true });

    const msgContainer = this.chatContainerRef.current;
    if (msgContainer) {
      const currentScrollHeight = msgContainer.scrollHeight;
      this.setState({ isLoading: true });
      try {
        const oldMsg = this.state.messages[0];
        const timeStamp = oldMsg ? oldMsg.createdAt : Date.now();
        const messages: ExtendedUserMessages[] = await this.state.channel.getMessagesByTimestamp(
          timeStamp,
          { isInclusive: false, prevResultSize: 50, nextResultSize: 0 }
        ) as ExtendedUserMessages[];
        this.dltUnnecessaryMsgs(messages);
        const filterMsgs = messages.filter((message: ExtendedUserMessages) => (message.customType !== 'read_receipt' && message.customType !== 'read_all_receipt' && message.customType !== 'online_receipt' && message.data !== ''));
        this.setState((prevState) => ({
          messages: [...filterMsgs, ...this.state.messages],
          isLoading: false,
          hasMore: messages.length > 0,
          isLoadingPrevMsg: false
        }), () => {
          const newHeightScroll = msgContainer.scrollHeight;
          msgContainer.scrollTop = newHeightScroll - currentScrollHeight;
          let lastMsg = this.state.messages[this.state.messages.length - 1];
          this.isLastMessage(lastMsg);
          this.updateAllMessagesReadStatus(false)
          !isPagination && this.messageScrollToBottom();
        });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    }
  };

  handleOnOrderDeclineModal = async (messageId: number, productId: string, message: string, isAccept: boolean) => {
    let declineData = {
      messageId,
      productId,
      message,
      isAccept
    }
    this.setState({ onOrderStatus: declineData, isDeclineModal: true });
  };

  toggleDeclineModal = () => {
    this.setState({ isDeclineModal: !this.state.isDeclineModal });
  };

  handleDeclineReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ declineReason: event?.target.value });
  };

  handleDeclineOtherReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    const wordCount = this.getWordCount(inputText);
    if(wordCount <= 100){
      this.setState({declineOtherReason : event?.target.value, declineOtherErr: false});
    }
  };

  getWordCount = (text: string) => {
    return text.trim().split(/\s+/).filter(Boolean).length;
  };

  handleAcceptOnOrder = (messageId: number, productId: string, message: string, expectedDate: string, isAccept: boolean) => {
    let acceptData = {
      messageId,
      productId,
      message,
      isAccept,
      expectedDate,
    };
    this.setState({ onOrderStatus: acceptData }, () => {
      this.handleOnOrderStatusUpdate();
    });
  };

  handleOnOrderStatusUpdate = async () => {
    const { channel, onOrderStatus } = this.state;
    if (!channel) return;
    if(!(onOrderStatus?.isAccept) && (this.state.declineReason === "Other" && !(this.state.declineOtherReason.trim()))){
      this.setState({declineOtherErr : true});
      return;
    }
    try {
      let messageData = JSON.parse(String(onOrderStatus?.message));
      messageData.onOrderStatus = onOrderStatus?.isAccept ? "Accepted" : "Declined";
      const params = {
        message: JSON.stringify(messageData),
        data: JSON.stringify({ delivered: true, read: true }),
        customType: "on_order",
      };
      const updatedMsgs = this.state.messages.map((message) => {
        if (message.messageId === onOrderStatus?.messageId) {
          message.message = JSON.stringify(messageData);
          return { ...message };
        }
        return message;
      });
      const updatedMessage = await channel.updateUserMessage(Number(onOrderStatus?.messageId), params);
      this.setState({ messages: updatedMsgs as ExtendedUserMessages[] });
      if (updatedMessage) {
        if (onOrderStatus?.isAccept) {
          let expectedDeliveryDate = moment(onOrderStatus?.expectedDate).format('DD/MM/YYYY');
          this.postOnOrderAcceptApiCall(onOrderStatus?.productId, expectedDeliveryDate);
        } else {
          this.postOnOrderRejectApiCall(String(onOrderStatus?.productId));
        }
      }
    } catch (error) {
    }
  };

  postOnOrderAcceptApiCall = async (productId: string, expectedDeliveryDate: string) => {
    this.postOnOrderAcceptApiId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.postOnOrderAcceptEndPoint,
      body: {
        id: parseInt(productId),
        expected_delivery: expectedDeliveryDate,
      },
      type: ""
    });
  };

  postOnOrderRejectApiCall = async (productId: string) => {
    const { declineOtherReason, declineReason } = this.state;
    this.postOnOrderRejectApiId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.postOnOrderRejectEndPoint,
      body: {
        id: parseInt(productId),
        cancellation_reason: declineReason === "Other" ? declineOtherReason : declineReason
      },
      type: ""
    });
  };

  apiCall = async (apiReqData: ApiPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiReqData;
    let token = await getStorageData("singupLogin");
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  acceptedOnOrderCallBack = (responseJson: OnOrderStatusApiResponse) => {
    this.setState({requestStatus: responseJson.requests.request_status},()=>{
      this.sendOrderStatusMessage(true, String(this.state.onOrderStatus?.productId));
    });
  };

  rejectOnOrderCallBack = (responseJson: OnOrderStatusApiResponse) => {
    this.setState({requestStatus: responseJson.requests.request_status, isDeclineModal: false},()=>{
      const cancelReason = responseJson.requests.cancellation_reason || "No reason provided"; 
      this.sendOrderStatusMessage(false, String(this.state.onOrderStatus?.productId), cancelReason);
    });
  };

  sendOrderStatusMessage = async (isOrderSuccess: boolean, productId: string, cancelReason?: string,) =>{
    const { channel } = this.state;
    try {
      const params: UserMessageCreateParams = {
        message: isOrderSuccess ? `Order Accepted (#${productId})` : `Order declined (#${productId}) - Reason - ${cancelReason}`,
        data: JSON.stringify({ delivered: false, read: false }),
      };
      channel?.sendUserMessage(params).onSucceeded(async (orderStatusMsg) => {
        if (orderStatusMsg) {
          const params = {
            customType: 'custom',
            data: JSON.stringify({ delivered: true, read: false }),
          };
          try {
            await channel.updateUserMessage(orderStatusMsg.messageId, params);
            this.refreshBuyerList();
          } catch (error) {
          }
          orderStatusMsg['data'] = JSON.stringify({ delivered: true, read: false });
          this.setState((prevState) => ({
            messages: [...prevState.messages, orderStatusMsg as ExtendedUserMessages],
            newMessage: '',
          }), this.messageScrollToBottom);
        }
      });
    } catch (error) {
    }
  };

  isLastMessage = (lastMsgs :ExtendedUserMessages) =>{
    if (lastMsgs && lastMsgs?.data && lastMsgs.sender.userId !== this.state.currentUserID) {
      let chatData = JSON.parse(lastMsgs.data);
      if (!chatData.read) {
        this.markAllMessageAsRead();
      }
    }
  };

  dltUnnecessaryMsgs = async (messages: ExtendedUserMessages[]) => {
    const { channel } = this.state;
    if (!channel) return;

    const unnecessaryMessages = messages.filter(
      (message) => message.customType === 'read_receipt' || message.customType === 'read_all_receipt'|| message.customType === 'online_receipt' 
    );
    for (const message of unnecessaryMessages) {
      if (message.sender.userId === this.state.currentUserID) {
        await channel.deleteMessage(message);
        await this.delayFn(100);
      }
    }
  };

  sendMessageSendBird = async () => {
    const { newMessage, channel } = this.state;
    if (newMessage.trim() === '') return;
    try {
      const params: UserMessageCreateParams = {
        message: newMessage,
        data: JSON.stringify({ delivered: false, read: false }),
      };
      channel?.sendUserMessage(params).onSucceeded(async (sentMsg) => {
        if (sentMsg) {
            const params = {
              customType: 'custom',
              data: JSON.stringify({ delivered: true, read: false }),
            };
            try {
              await channel.updateUserMessage(sentMsg.messageId, params);
              this.refreshBuyerList();
            } catch (error) {
            }
            sentMsg['data'] = JSON.stringify({ delivered: true, read: false });
            this.setState((prevState) => ({
              messages: [...prevState.messages, sentMsg as ExtendedUserMessages],
              newMessage: '',
            }), this.messageScrollToBottom);
          }
    });
    } catch (error) {
    }
  };

  messageScrollToBottom() {
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
    }
  };

  toggleSideBar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleDrawerToggle = () => {
    this.setState({ isChatListOpen: !this.state.isChatListOpen });
  };

  handleContactList = () => {
    this.setState({ isContactList: !this.state.isContactList });
  };

  handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newMessage: event.target.value });
  };

  handleKeyPress = () => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.sendMessageToUser();
      }, 300);
  };
  

  handleEmojiMartPicker = () => {
    this.setState({ isEmoji: !this.state.isEmoji });
  };

  handleEmojiMartSelect = (emoji: EmojiType) => {
    this.setState({ newMessage: this.state.newMessage + emoji?.native, isEmoji: !this.state.isEmoji });
  };

  handleEmojiClickOutside = (event: MouseEvent) => {
    if (this.emojiPickerRef.current && !this.emojiPickerRef.current.contains(event.target as Node)) {
        this.setState({ isEmoji: false });
    }
  };

  postCreateChannelApiCall = async (buyerId: string, sbUserId: string) =>{
      let token = await getStorageData("singupLogin");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };
        const body = {
            channel_type: "dm",
            user_ids: [buyerId, sbUserId],
            name: "openChannel",
            data: ""
        };
        const requestMessageCreateChannel = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageCreateChannel.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageCreateChannel.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );
        requestMessageCreateChannel.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCreateChannelEndPoint
        );

        requestMessageCreateChannel.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        runEngine.sendMessage(requestMessageCreateChannel.id, requestMessageCreateChannel);
        return requestMessageCreateChannel.messageId;
  };

  postCreateChannelSuccessCallBack = async (responseJson: CreateChannelApiResponse) => {
    this.setState({ channelCurrentUrl: responseJson?.channel_url, isContactList: !this.state.isContactList, isFromCreateApi: true, messages: [] }, async () => {
      await this.getSellerChannelsApiCall();
      this.getBuyerContactData();
      this.setState({ isSellerAppLoading: false });
    });
  };

  handleCreateChannel = async (buyerId : string) =>{
    this.setState({isSellerAppLoading: true});
    this.postCreateChannel = await this.postCreateChannelApiCall(buyerId, this.state.currentUserID);
  };

  handleSetBuyerChannel = (buyer: Buyer, chatIndex: number) => {
      this.setState({ selectedBuyer: buyer, messages: [], isReceiverOnline: false, currUserChatIndex: chatIndex,channelCurrentUrl : buyer.channelUrl }, () => {
        if(this.state.selectedBuyer){
          this.state.selectedBuyer['unreadCount'] = 0;
        }
        this.loadSellerChannel(this.state.selectedBuyer?.channelUrl || '');
      });
  };

  truncatedUserLastMessage = (lastMessage: string) => {
    if (lastMessage.length <= 16) {
        return lastMessage;
    }
    return lastMessage.substring(0, 16) + ' ...';
  }
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    this.setState({
      imageCurrentName: event.target.files[0].name,
      imageCurrentFile: event.target.files[0]
    });

    if (event.target.value && event.target.files) {
      const file = event.target.files[0] as Blob;
      let reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.setState({ fileCurrUrl: readerEvent.target?.result as string });
      };
      reader.readAsDataURL(file);
      this.setState({ isFileModal: true, isFileUploaded: true });
      event.target.value = "";
    }
    else {
      this.setState({ fileCurrUrl: "" })
    }
  };

  sendMessageToUser = async () => {
    if (!this.state.isFileUploaded) {
      if (this.state.newMessage.trim() !== "") {
        this.sendMessageSendBird();
      }
    } else {
      this.handleFileUpload();
    }
  };

  handleFileUpload = async () => {
    const { channel } = this.state;
    if (!channel) return;
    const fileMessageParams: FileMessageCreateParams = {
      customType: this.getFileType(),
      file: this.state.imageCurrentFile as FileCompat,
      data: JSON.stringify({ delivered: false, read: false, message: this.state.newMessage }),
    }
    channel?.sendFileMessage(fileMessageParams).onSucceeded(async (sentMessage) => {
      if (sentMessage) {
        const params = {
          data: JSON.stringify({ delivered: true, read: false, message: this.state.newMessage }),
          customType: this.getFileType(),
        };

        try {
          await channel.updateFileMessage(sentMessage.messageId, params);
        } catch (error) {
        }
        sentMessage['data'] = JSON.stringify({ delivered: true, read: false, message: this.state.newMessage });
        this.setState((prevState) => ({
          messages: [...prevState.messages, sentMessage as ExtendedUserMessages],
          isFileModal: false,
          newMessage: '',
          isFileUploaded: false,
          fileCurrUrl: '',
        }), this.messageScrollToBottom);
      }
    });
  };

  getAuthenticatedAccessUrl = (plainUrl: string) => {
    return plainUrl + '?auth=' + this.sendBird?.ekey;
  };

  handleFileClick = () => {
    if (this.fileRef.current) {
      this.fileRef.current.click();
    }
  };

  truncateDocumentName = (docName: string) => {
    if (docName.length <= 21) {
        return docName;
    }
    return docName.substring(0, 21) + ' ...';
   };

       
  getFileType = () => {
    if (this.state?.imageCurrentFile) {
      switch (this.state.imageCurrentFile.type) {
        case configJSON.applicationPdf:
          return configJSON.pdfText;
      case configJSON.applicationDoc:
          return configJSON.docText;
      case configJSON.applicationDocx:
          return configJSON.docxText;    
      default:
          return configJSON.imageText;
      }
    }
  };

  handleClickFileOpen = (fileUrl : string) =>{
    if (fileUrl.includes('.docx') || fileUrl.includes('.doc')) {
        const docUrl = `http://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`;
        const newTab = window.open(docUrl,"_blank");
        newTab?.focus();
    } else {
        const newWidnow = window.open(fileUrl, "_blank");
        newWidnow?.focus();
    }
  };

  formatSelectedDate = (dateInMiliSec: number = Date.now()): string => {
    const now = moment();
    const msgDate = moment(dateInMiliSec);
    if (now.isSame(msgDate, 'day')) {
      return 'Today';
    } else if (now.subtract(1, 'days').isSame(msgDate, 'day')) {
      return 'Yesterday';
    } else {
      return msgDate.format('MMMM D, YYYY');
    }
  };   

  handleClickBack = () =>{
    this.setState({isContactList : false});
  };

  handleImageModalUrl = (imageUrl: string) => {
    this.setState({ imageOpenUrl: imageUrl, isImageModalOpen: !this.state.isImageModalOpen })
  };

  handleImageModalSelect = () =>{
    this.setState({isImageModalOpen: false })
  };

  handleNavigateToDashboard = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SellerDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };

  checkLastMessage = (buyerListData: Buyer) => {
    if (buyerListData?.lastMessage !== null && buyerListData?.lastMessage) {
      return this.truncatedUserLastMessage(buyerListData?.lastMessage)
    } else if (buyerListData?.lastMessage === undefined && buyerListData.lastMessageTime !== 0) {
      return (
        <Box className="lastMsgAttachment">
          <AttachmentIcon className="attachmentIcon" /> 
          <span>{configJSON.attachmentText}</span>
        </Box>
      )
    }
  };

  checkUserMessageType = (chatData: ExtendedUserMessages) => {
    if (chatData.message && chatData.customType !== "on_order" && chatData.customType !== "bargain") {
      return true;
    } else {
      return false;
    }
  }; 

  formateDateChat = (chatData: ExtendedUserMessages) => {
    return moment(chatData.createdAt).format('hh:mm');
  };
    // Customizable Area End
}