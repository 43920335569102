import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Button,
    InputAdornment,
    IconButton,
    withStyles,
    TextField,
    Checkbox,
    InputLabel,
    Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Grid from '@material-ui/core/Grid';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";

import { appleLogo, close, facebookLogo, googleLogo, loginFormbg, IndianFlag, logo, buyerLoginImg } from "./assets"

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Box>
                    <Grid container className={classes.root}>
                        <Grid item md={6} className={`hidden-xs ${classes.display} ${classes.paper} ${classes.grayBackground}`}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                            <div className={classes.padding}>
                                <Box className={classes.leftBuyerloginImg} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px', maxWidth: 'max-content', margin: 'auto' }}>
                                    <Box className={classes.appLogo} >
                                        <img src={logo} alt="logo" className={classes.logoImage} />
                                    </Box>
                                    <img style={{ maxWidth: '100%' }} src={buyerLoginImg} alt="image"></img>
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} className={`${classes.paper} ${classes.blackBlackground}`}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px' }}>
                            <Box style={{ backgroundColor: '#000000', width: '100%', height: '100%', position: 'relative' }}>
                                <Container maxWidth="sm" className={classes.zIndex}>

                                    <div>
                                        <h1 className={`${classes.whiteText} ${classes.loginHeading}`}>Welcome Back</h1>
                                        <div className={`${classes.largeMarginBottom} ${classes.whiteText}`}>Get started on Flixoo live</div>
                                        <Formik initialValues={this.state.loginFormInitialValues} onSubmit={this.handleSubmit}>
                                            <Form className={classes.buyerLoginForm}>
                                                <div className={classes.marginBottom}>
                                                    <TextField
                                                        data-test-id="txtInputEmail"
                                                        type="text"
                                                        name="user"
                                                        variant="outlined"
                                                        label="Email/Mobile/Username"
                                                        InputLabelProps={{ shrink: true }}
                                                        className={classes.customTextField}
                                                        onChange={this.handleUserInputChange}
                                                        value={this.state.loginFormInitialValues.user}
                                                        fullWidth
                                                        style={{ borderRadius: '10px' }}
                                                    />
                                                    <Typography component={'p'} className={classes.loginErrorMessage}>{this.state.userLoginError}</Typography>
                                                </div>
                                                <div>
                                                    <TextField
                                                        label='Password'
                                                        variant="outlined"
                                                        name="password"
                                                        type="text"
                                                        InputLabelProps={{ shrink: true }}
                                                        className={classes.customTextField}
                                                        onChange={this.handlePasswordChange}
                                                        onKeyDown={this.handlePasswordKey}
                                                        data-test-id="txtPassword"
                                                        value={this.state.showPassword ? this.state.copyPassword : "*".repeat(this.state.loginFormInitialValues.password?.length)}
                                                        fullWidth
                                                        autoComplete="off"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={this.handleClickShowPassword.bind(this)}
                                                                        onMouseDown={this.handleClickShowPassword.bind(this)}
                                                                    >
                                                                        {this.state.showPassword ? <VisibilityOff style={{ color: 'white' }} /> : <Visibility style={{ color: 'white' }} />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    <Typography component={'p'} className={classes.loginErrorMessage}>{this.state.userPasswordError}</Typography>
                                                </div>
                                                <div className={classes.displayFlexBetween}>
                                                    <Box sx={{ display: 'flex', gridGap: '10px' }} className={classes.displayFlexAlignCenter}>
                                                        <Checkbox
                                                            color="primary"
                                                            data-test-id="remember"
                                                            checked={this.state.loginFormInitialValues.rememberMe}
                                                            onClick={this.handleRememberMeChange}
                                                        />
                                                        <InputLabel className={`${classes.whiteText} ${classes.rememberMeContainer}`}>
                                                            Remember me
                                                        </InputLabel>
                                                    </Box>
                                                    <div className={`${classes.whiteText} ${classes.rememberMeContainer} ${classes.cursorPointer}`}><Link to='/BuyerForgotPassword' style={{ color: 'white' }}><u>Forgot Password?</u></Link></div>
                                                </div>
                                                <Button type="submit" data-test-id="btnEmailLogIn" variant="contained" className={classes.loginButton}>
                                                    Login
                                                </Button>
                                            </Form>
                                        </Formik>
                                        <LoginFailModalWeb
                                            displayPopup={this.state.displayPopup}
                                            closeErrorPopup={this.closeErrorPopup}
                                            titleMessage="Invalid Credentials"
                                            errorMessage={this.state.errorMessage} />
                                        <div className={`${classes.orSection} ${classes.whiteText}`}>OR</div>
                                        <div className={classes.displayFlexAround}>
                                            <div className={classes.socialSignInLogo}>
                                                <img data-test-id="connectGoogle" onClick={this.handleGoogleLoginClick} src={googleLogo} alt="Google_Logo" />
                                            </div>
                                            <div className={classes.socialSignInLogo}>
                                                <img src={facebookLogo} alt="facebook"></img>
                                            </div>
                                            <div className={classes.socialSignInLogo}>
                                                <img src={appleLogo} alt="apple"></img>
                                            </div>
                                        </div>
                                        <div className={`${classes.displayFlexCenter} ${classes.whiteText} ${classes.largeMarginTop}`}>
                                            Don't have an account?<Link className={`${classes.yellowText} ${classes.cursorPointer}`} to="/AccountRegistration" >  <span >&nbsp;Sign Up </span></Link>
                                        </div>
                                        <Box className={classes.MadeInBharat}>
                                            <Box sx={{ display: 'flex', gridGap: '5px' }}>
                                                <img src={IndianFlag} alt="" />
                                                <Typography component={'p'} style={{ width: '100px' }}>Made in Bharat</Typography>
                                            </Box>
                                        </Box>
                                    </div>
                                </Container>
                                <div style={{ marginTop: 'auto' }} className={classes.waveImgBox}>
                                    <img src={loginFormbg} alt="" />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
        );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles: any = {
    root: {
        flexGrow: 1,
        height: '100vh'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    grayBackground: {
        backgroundColor: "404040",
        width: '100%',
        marginTop: '-1px',
    },
    blackBlackground: {
        backgroundColor: "000000"
    },
    padding: {
        width: '100%',
    },
    appLogo: {
        alignSelf: 'start',
        '& img': {
            maxWidth: '231px',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'initial',
            backgroundColor: '#424242',
            marginTop: '50px',
        },
    },
    ovalImage: {
        position: "relative",
        left: "-35%",
        width: "154px",
        height: '154px',
        borderRadius: '50%',
        backgroundColor: '#edb3ce',
    },
    leftBuyerloginImg: {
        '& img': {
            width: '750px',
            marginTop: '70px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    },
    ovalText: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50% , -50%)',
        fontSize: "20px",
        width: '73%',
        '& p': {
            color: '#000000',
            fontFamily: 'Poppins-Medium !important',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "28px"
        },
    },
    display: {
        zIndex: '1',
        [theme.breakpoints.up('md')]: {
            width: "50%"
        }
    },
    buyerLoginForm: {
        "& .MuiOutlinedInput-root": {
            borderRadius: '10px'
        },
    },
    marginTop: {
        marginTop: "20px"
    },
    largeMarginTop: {
        marginTop: "5vh",
        fontSize: '18px',
        lineHeight: '27px',
    },
    marginBottom: {
        marginBottom: "30px"
    },
    largeMarginBottom: {
        marginBottom: "11vh"
    },
    customTextField: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '808080',
        },
        '& .MuiFormLabel-root': {
            color: '#808080'
        },
        ' & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '808080',
        },
        '& .MuiOutlinedInput-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '808080'
        },
        '& .MuiInputBase-input': {
            color: '#ffffff',
            '&:focus': {
                caretColor: 'white',
            },
        }
    },
    whiteText: {
        color: '#ffffff',
    },
    loginHeading: {
        fontSize: '60px',
        lineHeight: '90px',
        marginBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '40px',
            lineHeight: '60px',
        },
    },
    yellowText: {
        color: '#f5ea16',
        textDecoration: 'none',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
    },
    backgroundImage: {
        position: 'absolute',
        right: '0',
        bottom: '0',
    },
    displayFlexBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        "& .MuiCheckbox-root": {
            color: "white",
            padding: '0px',
            backgroundColor: '#ffffff',
            width: '17px',
            height: '17px',
            borderRadius: '5px',
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16"
        }
    },
    rememberMeContainer: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 'larger'
    },
    loginButton: {
        padding: '12px 35%',
        backgroundColor: '#F5EA16 !important',
        borderRadius: '25px',
        marginTop: '5vh',
        marginBottom: '3vh',
        fontSize: 'large',
        fontWeight: '600',
        textTransform: 'none',
        color: 'black !important',
        '&:hover': {
            backgroundColor: '#F5EA16',
        },
    },
    orSection: {
        fontWeight: 'bolder',
        fontSize: 'larger',
        marginBottom: '3vh'
    },
    displayFlexAround: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    socialSignInLogo: {
        padding: '8px 20px',
        border: '1px solid #808080',
        borderRadius: '15px',
        cursor: 'pointer'
    },
    displayFlexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    displayFlexAlignCenter: {
        display: 'flex',
        alignItems: 'center',
        "& .MuiCheckbox-root": {
            color: "white",
            background: "white",
            height: "17px",
            width: "17px",
            marginRight: '12px'
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16",
            background: "black"
        },
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    zIndex: {
        zIndex: '9',
        height: '100%',
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '1fr',
        justifyContent: 'center',
        alignItems: 'center'
    },
    popUpTitle: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '18px',
        padding: '5px 0'
    },
    popUpContent: {
        color: 'black',
        fontSize: '18px',
        padding: '10px'
    },
    loginErrorMessage: {
        color: 'red',
        textAlign: 'start',
        marginTop: '5px',
    },
    MadeInBharat: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
        '& p': {
            color: '#ffffff',
            fontSize: '12px',
            letterSpacing: '0.3px'
        },
    },
    waveImgBox: {
        '& img': {
            position: 'absolute',
            right: '0px',
            bottom: '0px',
            [theme.breakpoints.down('sm')]: {
                position: 'initial',
                marginLeft: '-50',
            },
        },
    },
}
export default withStyles(styles)(EmailAccountLoginBlock)
export { EmailAccountLoginBlock }
// Customizable Area End
