import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import SendbirdChat, { SendbirdChatWith } from "@sendbird/chat";
import { GroupChannel, GroupChannelFilter, GroupChannelListOrder, GroupChannelModule } from "@sendbird/chat/groupChannel";
import { UserMessageCreateParams } from "@sendbird/chat/message";
export const SENDBIRD_INFO = { appId: '3A2E0550-B94A-4269-989F-D0E5A31FA5FB' };
export const baseConfig = require('../../../framework/src/config');
const baseUrl = baseConfig.baseURL;
export interface SizeDataI{
		id: string;
		type: string;
		attributes: {
			id: string;
			catalogue_id: string;
			catalogue_variant_size: {
				id: number;
				name: string;
				created_at: string;
				updated_at: string
			} | null;
			price: string;
			stock_qty: number;
			created_at: string;
			updated_at: string
		}
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
  type: string;
}

interface DataPayloadType {
  attributes: {
    id: string
  }
}

interface MakeOfferApiPayload{
  channel_url: string,
  data: DataPayloadType;
}

export interface CataglogueI {
  catalogue_variant_color_id: number;
  catalogue_variant_images: [
    {
      id: number;
      url: string;
    }
  ]
  catalogue_variant_videos: [
    {
      id: number;
      url: string;
    }
  ];
  sizes_data: Array<SizeDataI>
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any
  classes?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  productDescription: any;
  selectedImage: any;
  selectedVideo: any;
  isShowingVideo: boolean;
  relatedProduct: any;
  token: any;
  buyerToken: any;
  deliveryInfo: any;
  pincode: any;
  catalogueVariants: any;
  selectedVariant: any;
  isAddingToCart: boolean
  productId: string
  messageModel: boolean;
  loading: boolean;
  message: string;
  wishListProduct: any;
  subCategoryIds: string
  uniqueProductSize: any;
  colorBasedOnSize: any;
  productPrice: string;
  openTunedBox: boolean
  tokenPresentOrNot: null | string
  sizeChartopen: boolean;
  showCatalogue: boolean;
  colorList: Array<string>;
  productMrp: string;
  activeSize: {
    sizeID: string;
    sizeName: string | null
  };
  isMakeOfferModal: boolean;
  offerValue: number | string;
  percentageStep: number;
  offerValueError : string;
  channel: GroupChannel | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productDescriptionAPICallId: any;
  relatedCatalogueAPICallId: any;
  getNewSellerTokenCallId: any;
  getNewBuyerTokenCallId: any;
  deliveryInforCallId: any;
  addToCartCallId: any;
  similarAddToCartCallId: any;
  apiAddToWishList: string = ''
  apiDeleteFromWishList: string = ''
  apiTopSearch: string = ''
  makeOfferApiCallId: string = "";
  sendBird: SendbirdChatWith<GroupChannelModule[]> | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false
      // Customizable Area Start
      ,productDescription: null,
      selectedImage: null,
      selectedVideo: null,
      isShowingVideo: false,
      relatedProduct: null,
      token: null,
      deliveryInfo: null,
      pincode: "",
      catalogueVariants: null,
      selectedVariant: null,
      buyerToken: null,
      isAddingToCart: false,
      productId: '',
      messageModel: false,
      loading: false,
      message: '',
      wishListProduct: [],
      subCategoryIds: '',
      uniqueProductSize: [],
      colorBasedOnSize: [],
      productPrice: '',
      openTunedBox: false,
      tokenPresentOrNot:null,
      sizeChartopen:false,
      showCatalogue: false,
      colorList: [],
      productMrp: "",
      activeSize: {
        sizeID: "",
        sizeName: ""
      },
      isMakeOfferModal: false,
      offerValue: "",
      percentageStep: 0,
      offerValueError: "",
      channel: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIResponse(message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  closeMessageModal = () => {
    this.setState({
      messageModel: !this.state.messageModel
    })
  }

  // Customizable Area Start

  StayTunedBox = () => {
    this.setState({
      openTunedBox: !this.state.openTunedBox
    });
  }

  getProductDescription = async() => {
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.productDescriptionAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/catalogue_details?id=${this.state.productId}`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: buyerToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getRelatedCalogue = async(subCategoryId: any) => {
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.relatedCatalogueAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/related_catalogue?sub_category_id=${subCategoryId}&catalogue_id=${this.state.productId}`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: buyerToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTopSearchData = async (subCateQueryUrl: string) => {
    this.setState({ loading: true });
    const headers = {"Content-Type": configJSON.validationApiContentType,
      token: await getStorageData('buerLoginToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTopSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getTopSearchEndPoint}${subCateQueryUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.similarAddToCartCallId:
          this.setStateCart(responseJson)
          break;
        case this.getNewSellerTokenCallId:
          const token = responseJson.meta.token;
          this.setState({ token });
          break;
        case this.getNewBuyerTokenCallId:
          this.getProductDescription();
          break;
        case this.productDescriptionAPICallId:
          if(responseJson.data){
            this.getRelatedCalogue(
              responseJson.data.attributes.sub_category.id
            );
            const toNestedArray:Array<[string, CataglogueI]> = Object.entries(responseJson.data.attributes.catalogue_variants);
            this.setState({
              productDescription: responseJson.data,
              selectedImage: toNestedArray[0][1].catalogue_variant_images[0],
              catalogueVariants: toNestedArray,
              colorList: Object.keys(responseJson.data.attributes.catalogue_variants),
              subCategoryIds: responseJson.data.attributes.sub_category.id,
              productPrice: toNestedArray[0][1].sizes_data[0].attributes.price,
              selectedVariant: toNestedArray[0][1],
              activeSize: {
                sizeID: toNestedArray[0][1].sizes_data[0].attributes.id,
                sizeName: toNestedArray[0][1].sizes_data[0].attributes.catalogue_variant_size 
                ?  toNestedArray[0][1].sizes_data[0].attributes.catalogue_variant_size?.name : null
              }
              
            });
          }
          break;
        case this.relatedCatalogueAPICallId:
          this.setState({ relatedProduct: responseJson.data });
          break;
        case this.deliveryInforCallId:
          responseJson.message === "Data Fetch successfully"
            ? this.setState({ deliveryInfo: responseJson })
            : this.setState({ deliveryInfo: null });
          break;
        case this.addToCartCallId:
          this.setStateCart(responseJson)
          break;
        case this.apiAddToWishList:
          this.checkItemAddedWishList(responseJson);
          break;
        case this.apiDeleteFromWishList:
          this.checkItemRemovedWishList(responseJson);
          break;
        case this.apiTopSearch:
          this.setState({ loading: false, wishListProduct: responseJson?.wishlist_items?.product_ids })
          break;
        case this.makeOfferApiCallId:
          this.handleMakeOfferApiCallBack(responseJson);
          break;
        default:
          break;
      }
    }
  }

  setStateCart = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        loading: false,
        message: responseJson.message,
        messageModel: true,
        isAddingToCart: false
      })
    } else if (responseJson.errors) {
      this.setState({
        loading: false,
        message: responseJson.errors,
        messageModel: true
      })
    }
  }

  checkItemAddedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getTopSearchData('');
      this.getRelatedCalogue(this.state.subCategoryIds);
      this.closeMessageModal();
      this.setState({ message: responJson.message })
    } else {
      this.closeMessageModal();
      this.setState({ message: responJson.errors })
    }
  }

  checkItemRemovedWishList = (responJson: any) => {
    if (responJson.message) {
      this.getTopSearchData('');
      this.getRelatedCalogue(this.state.subCategoryIds);
      this.closeMessageModal();
      this.setState({ message: responJson.message })
    } else {
      this.closeMessageModal();
      this.setState({ message: responJson.errors[0] })
    }
  }

  checkProductStatus = (producArray: any, ID: any) => {
    const index = producArray?.findIndex((item: any) => item.product_id === ID);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  }

  deleteProductItem = (producArray: any, ID: any) => {
    const index = producArray.find((item: any) => item.product_id === ID).favourite_id;

    if (index) {
      this.deleteWishListItem(index)
    } else {
      return false;
    }
  }

  getNewSellerToken = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNewSellerTokenCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_login/logins/seller_login`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const body = {
      key: configJSON.exampleSellerEmail,
      password: configJSON.exampleSellerPassword
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNewBuyerToken = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNewBuyerTokenCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_login/logins/buyer_login`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const body = {
      key: configJSON.exampleBuyerEmail,
      password: configJSON.exampleBuyerPassword
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  getDeliveryInfo = async(pincode: string, token: any, event: any) => {
    event.preventDefault();
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deliveryInforCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/catalogue_pincode?pincode=${pincode}`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: buyerToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  formatDate = (dateString: string) => {
    let date = new Date(dateString);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let dayName = days[date.getDay()];
    let day = date.getDate();
    let monthName = months[date.getMonth()];
    return `${dayName}, ${day} ${monthName}`;
  };

  async componentDidMount() {
    const array = window.location.pathname.split("/");
    const prodId = array[array.length - 1];
    const tokenPresentOrNot =  await getStorageData('buerLoginToken')
    this.setState({tokenPresentOrNot:tokenPresentOrNot})
    this.getTopSearchData('')
        if(prodId !== null){
          this.setState({ productId: prodId });
        }
    if(tokenPresentOrNot !== null){
      this.initializeSendBirdSDK();
    }
  }

  initializeSendBirdSDK = async () => {
    const buyerData = await getStorageData("Buyer_Data");
    const buyerObj = JSON.parse(buyerData);
    let buyerId = await buyerObj?.sendbird_credential?.user_id;
    let buyerToken = await buyerObj?.sendbird_credential?.access_token;
    const sendbirdChat = SendbirdChat.init({
      appId: SENDBIRD_INFO.appId,
      localCacheEnabled: true,
      modules: [new GroupChannelModule()]
    });
    try {
      await sendbirdChat.connect(buyerId, buyerToken);
    } catch (error) {
    }
    await sendbirdChat.setChannelInvitationPreference(true);
    this.sendBird = sendbirdChat; 
  };

  loadChannels = async (channelURL: string, requestId: string) => {
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true;
    const collection = this.sendBird?.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    });
    const channels = await collection?.loadMore();
    if (channels && channels.length > 0) {
      let channelIndex = channels.findIndex((channel) => channel.url === channelURL);
      this.setState({ channel: channels[channelIndex] }, () => {
        this.sendMessageSendBird(requestId);
      });
    }
  };

  sendMessageSendBird = async (requestId: string) => {
    const { channel, selectedVariant, productDescription } = this.state;
    if (!channel) return;
    try {
      const messageData = JSON.stringify({ productId: requestId, bargainStatus: "pending", productTitle: productDescription?.attributes.title, productDesc: productDescription?.attributes.description , askPrice: this.state.offerValue, productImage: baseUrl + selectedVariant?.catalogue_variant_images[0].url});
      const param: UserMessageCreateParams = {
        message: messageData,
        data: JSON.stringify({ delivered: false, read: false }),
        customType: 'bargain',
      };
      channel?.sendUserMessage(param).onSucceeded(async (sentMessage) => {
        if (sentMessage) {
          const params = {
            customType: 'bargain',
            data: JSON.stringify({ delivered: true, read: false }),
          };
          try {
            await channel.updateUserMessage(sentMessage.messageId, params);
          } catch (error) {
          }
          sentMessage['data'] = JSON.stringify({ delivered: true, read: false });
        }
      });
      this.setState({ offerValue: '', offerValueError: '' }, ()=> {
        this.navigateToChatScreen();
      });
    } catch (error) {
    }
  };

  handlePaginationChange = () => {
    const headingElement = document.getElementById("myref")
    if(headingElement) headingElement.scrollIntoView({behavior: 'smooth'})
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.productId !== prevState.productId) {
      this.getProductDescription()
    }
  }

  handleSelectedImage = (img: any) => {
    this.setState({ selectedImage: img, isShowingVideo: false });
  };

  handleSelectedVideo = (vid: any) => {
    this.setState({ selectedVideo: vid, isShowingVideo: true });
  };

  handleSelectVariant = (variant: [string, CataglogueI]) => {
    this.setState({
      selectedVariant: variant[1],
      selectedImage: variant[1].catalogue_variant_images[0],
      activeSize:  {
        sizeID: variant[1].sizes_data[0].attributes.id,
        sizeName: variant[1].sizes_data[0].attributes.catalogue_variant_size 
        ?  variant[1].sizes_data[0].attributes.catalogue_variant_size?.name : null
      },
      productPrice: variant[1].sizes_data[0]?.attributes.price,
      showCatalogue: true,
      isShowingVideo: false
    })
  }
  checkToken=()=>{
    if(this.state.tokenPresentOrNot === null){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message)
    }
  }

  handleAddToCart = async() => {
    this.checkToken()
    this.setState({ loading: true });
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToCartCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_order_management/add_item_to_cart`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": buyerToken
    };

    const body = {
      catalogue_id: this.state.productDescription.id,
      catalogue_variant_id: this.state.activeSize.sizeID,
      quantity: 1
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({isAddingToCart: true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSimilarAddToCart = async(catalogue_id: number, catalogue_variant_id: number) => {
    this.checkToken()
    const buyerToken = await getStorageData('buerLoginToken')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.similarAddToCartCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_order_management/add_item_to_cart`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": buyerToken
    };

    const body = {
      catalogue_id: catalogue_id,
      catalogue_variant_id: catalogue_variant_id,
      quantity: 1
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({isAddingToCart: true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateWishListItem = async (id: number, type: string) => {
    this.checkToken()
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "token": buyertoken,
        "Content-Type": "application/json"
      };
      if (type == "catalogue") {
        type = "product"
      }
      const body = {
        "data": {
          "type": type,
          "favouriteable_id": id
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiAddToWishList = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body));

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.favouriteItemEndPoint
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.exampleAPiMethod);

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  deleteWishListItem = async (id: number) => {
    this.checkToken()
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = {
        "Content-Type": "application/json",
        "token": buyertoken,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiDeleteFromWishList = requestMessage.messageId;

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.favouriteItemEndPoint}/${id}`
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleSizeChartClose=()=>{
    this.setState({sizeChartopen:false})
  }
  handleOpenSizeChart = () => {
    this.setState({ sizeChartopen: true })
  }

  handleSize = (sizeData:SizeDataI) => {
    this.setState({
      productPrice: sizeData.attributes.price,
      activeSize: {
        sizeID: sizeData.attributes.id,
        sizeName: sizeData.attributes.catalogue_variant_size ? sizeData.attributes.catalogue_variant_size?.name : null
      }
    });
  }
  handleProdNavigate = (prodId: number) =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),"ProductDescription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), prodId);
    this.send(message)
  };

  pincodeValidator = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberReg = configJSON.pincodeRegx;
    if (numberReg.test(event.target.value) || event.target.value === "") {
      this.setState({
        pincode: event.target.value,
      });
    }
  }

  handleModalToggle = () => {
    this.checkToken();
    this.setState({ isMakeOfferModal: !this.state.isMakeOfferModal });
  };

  handleOfferChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { productPrice } = this.state;
    const value = event.target.value;
    const regex = configJSON.offerValueRegex;
    if (regex.test(value)) {
      const numericValue = parseFloat(value);
    const numericProductPrice = parseFloat(productPrice); 
      if (numericValue > numericProductPrice) {
        this.setState({ offerValueError: "Your price cannot be greater than base price" });
      }else{
        this.setState({ offerValueError: "" });
      }
      this.setState({ offerValue: value, percentageStep: 0 });
    }
  };

  handleOfferApply = (value: number, percentage: number) => {
    const { productPrice, percentageStep } = this.state;
    if (percentageStep !== value) {
      const discountPrice = parseFloat((Number(productPrice) - (Number(productPrice) * percentage) / 100).toFixed(2));
      this.setState({ percentageStep: value, offerValue: discountPrice, offerValueError: '' });
    } else {
      this.setState({ percentageStep: 0, offerValue: '', offerValueError: '' });
    }
  };

  postMakeOfferApiCall = async () => {
    this.makeOfferApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.makeOfferApiEndPoint,
      body: {
        data:{
          "catalogue_id": Number(this.state.productId),
          "ask_price": this.state.offerValue
        }
      },
      type: "",
    });
  };

  handleMakeOffer = () => {
    this.checkToken();
    if(this.state.offerValueError !== "" || this.state.offerValue === '' || Number(this.state.offerValue) === 0){
      this.setState({offerValueError: "Please enter a valid offer price"});
      return;
    }
    this.postMakeOfferApiCall();
  };

  handleMakeOfferApiCallBack = (responseJson: MakeOfferApiPayload) => {
    this.setState({ loading: false });
    this.loadChannels(responseJson.channel_url || '', responseJson.data.attributes.id || '');
  };

  navigateToChatScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ChatScreen");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  apiCall = async (apiReqData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiReqData;
    this.setState({ loading: true, isMakeOfferModal: false });
    const buyerToken = await getStorageData('buerLoginToken');
    const header = {
      token: buyerToken,
      "Content-Type": contentType,
    };
    const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body && type != 'formData' ?
      requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body)) :
      requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
    return requestApiMessage.messageId;
  };
  // Customizable Area End
}
